// ** Base changes
svg:focus{
   outline: none;
}

// ** Bootstrap changes
.form-control{
   padding: 0.75rem 1rem;
   transition: all 100ms ease-in-out;

   &[type=file]{
      padding-left: 1.2rem;
   }
}
.invalid-feedback{
   font-size: 1rem;
   font-weight: 500;
}
.form-label{
   font-size: 1rem;
   margin-bottom: 0.5rem;
}

.btn.btn-line-form {
   width: 42px;
   line-height: 20px;
}

.btn.btn-flat-primary {
   &:hover {
      border-color: transparent;
   }

   &.disabled {
      border-color: transparent;
   }
}

.input-group{
   .input-group-text{
      &.input-group-skip-focus{
         border-left: 0;
      }
   }
}

.table{
   &.condensed > :not(caption) > * > * {
      padding: 0.5rem;
   }   
}

.table thead th, .table tfoot th {   
   font-size: 0.8rem;
      
   > .table-header-title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;   
   }   
}

.dropdown-menu{
   button.dropdown-item{
      width: 100%;
   }
}

.dark-layout input:-webkit-autofill,
.dark-layout textarea:-webkit-autofill,
.dark-layout select:-webkit-autofill{
   -webkit-box-shadow: 0 0 0 1000px #1e253a inset !important;
}

.dark-layout input.form-control:not(:focus),
.dark-layout select.form-select:not(:focus),
.dark-layout textarea.form-control:not(:focus) {
    border-color: #1c2338;
}

.dark-layout input.form-control,
.dark-layout select.form-select,
.dark-layout textarea.form-control {
   background-color: #1e253a;
}

.dark-layout .input-group .input-group-text{   
   border-width: 2px;
   border-color: #1c2338;
   background-color: #1e253a;
}
.dark-layout .input-group input.disabled-same-bg {
   opacity: 1;   
}

.dark-layout {
   .input-group {
      &.input-group-filled{         
         .input-group-text {
            background-color: #283046;
         }

         &.disabled {
            .input-group-text {
               background-color: #1e253a;
               opacity: 0.5;
            }            
         }
      }
   }
}
.dark-layout .select__control {
    background-color: #1e253a !important;
    border-color: #1c2338 !important;
}
.react-select.is-invalid .select__control {
    border-color: #f1416c !important;
}
.select__control{
   min-height: 43px !important;
}
.select__multi-value, .react-select__multi-value {
   margin: 0.25rem 0.50rem 0.25rem 0 !important;
}

.select__menu {
   z-index: 1050 !important;
}

.table-flush-spacing{
   .form-label{
      margin-bottom: 0;
   }
}

.bg-light-dark{
   background: #f8f8f8;
}

.dark-layout .bg-light-dark{
   background: #161d31;
}

.bg-light-body {
   background: rgba(0,0,0,0.1);
   color: #6e6b7b;
}

.dark-layout .bg-light-body {
   color: #b4b7bd;
}
.card {
   .card-title {
      font-size: 1.2rem;      
   }
} 
@media (min-width: 1200px) {
   h4, .h4 {
      font-size: 1.2rem;
   }
}

// ** Badge
.badge {
   &.badge-up {
      &.stand-alone {
         padding: 0;
         min-width: 1rem;
         min-height: 1rem;
      }
   }
   &.badge-remove-file{
      right: -10px !important;
      top: -10px !important;
      padding: 0.3rem !important;
   }
}

.badge-visible-hover{
   .badge {
      display: none;
   }
   &:hover{
      .badge{
         display: block;
      }
   }
}

// ** LicencePlate
.licence-plate{
   background-image: url('../images/backgrounds/licence-plate.svg');
   background-size: 102px 22px;
   width: 102px;
   height: 22px;
   padding: 2px 0px 2px 14px;
   font-weight: 600;
   font-size: 12px;
   color: #000;
   text-align: center;

   &.lg{
      background-size: 116px 25px;
      width: 116px;
      height: 25px;
      line-height: 23px;
      font-size: 1rem;
   }
}

.image-wrapper{
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-shrink: 0;
   line-height: 1;
   border-radius: 0px;
   overflow: hidden;
   border: 3px solid rgba(0,0,0,0.1);
   width: 72px;
   height: 48px;

   &.lg{
      width: 100%;
      max-width: 310px;
      height: auto;
      cursor: pointer;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }

      .image-holder{
         width: 100%;
         max-height: 204px;

         img {
            max-height: 204px;
         }
      }
   }

   .nav-pills .nav-link img{
      width: 100%;
      height: 100%;
      text-align: center;
      object-fit: cover;
      color: transparent;
      text-indent: 10000px;
   }
}

img.image-modal {
   max-width: 100%;
   max-height: 500px;
}

// ** Table
.table {
   &.table-striped{     
      > thead {
         > tr {
            > th.highlight-day, > th.highlight-sort-day {               
               position: relative;               
               
               &:after, &:before {
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  height: 100%;
                  width: 1px;
                  background: rgba(#299170, 50%);
                  content: "";
               }

               &::before {
                  left: auto;
                  right: 0px;
               }               
            }
            
            > th.highlight-sort-day {
               &:after, &:before {                  
                  background: rgba(#8678ff, 50%);
               }
            }
         }
      } 
      > tbody {
         > tr.highlight {
            > td:first-child {
               --bs-table-accent-bg: #fcebeb !important;
               background: #fcebeb !important;
            }            
         }
         > tr {
            > td.highlight-day, > td.highlight-sort-day {               
               position: relative;
               
               &:after, &:before {
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  height: 100%;
                  width: 1px;
                  background: rgba(#299170, 50%);
                  content: "";
               }

               &::before {
                  left: auto;
                  right: 0px;
               }
            }

            > td.highlight-sort-day {
               &:after, &:before {                  
                  background: rgba(#8678ff, 50%);
               }
            }
         }
      }
   }

   &.table-thead-fixed {        
      > thead {         
         position: sticky;         
         top: 0px;
         z-index: 1;     
         border-top: none !important;
         border-bottom: none !important;
         //box-shadow: 0px 1px 0px 0px #3b4253, 0px -1px 0px 0px #3b4253;         

         > tr {
            border-bottom: 0px !important;
         }

         &::after {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 1px;
            background: #3b4253;
            content: " ";
         }
         &::before {
            position: absolute;
            left: 0px;
            bottom: -1px;
            width: 100%;
            height: 2px;
            background: #3b4253;
            content: " ";
         }
      }
   }
}
.dark-layout {
   .table {
      &.table-striped{
         > tbody {
            > tr.highlight {
               > td:first-child {
                  --bs-table-accent-bg: #291938 !important;
                  background: #291938 !important;
               }          
            }
         }
      }
   }
}
.dark-layout {
   .table {
      thead > tr {
         border-color: #343d55 !important;            
      }
      tbody > tr > td{
         border-bottom: 1px solid #343d55 !important;  
         
         .table {
            tbody tr:last-child td {
               border-bottom: 0px !important;
            }
         }   
      }      

      &.table-striped{     
         > tbody {
            > tr {
               &:nth-child(2n+1) {                  
                  td {                                   
                     --bs-table-accent-bg: darken(#283046, 2%);
                     background: darken(#283046, 2%);
                  }
               }
            }
         }                                     
      }

      &.table-hover{
         > tbody {
            > tr {
               &:hover{
                  td{
                     --bs-table-hover-bg: darken(#283046, 4%);               
                     background: darken(#283046, 4%);
                  }
               }
            }
         }
      }
   }
}

//** Table corrections
.react-dataTable{
   .rdt_Table {
      display: table;
      border-collapse: collapse;

      .rdt_TableHead {
         display: table-row-group;

         .rdt_TableHeadRow {
            display: table-row;

            .rdt_TableCol{
               display: table-cell;
               vertical-align: middle;
               padding: 0.5rem 1rem !important;
            }
         }
      }
      .rdt_TableBody{
         display: table-row-group;

         .rdt_TableRow{
            display: table-row;
            border-bottom: 1px solid rgba(0,0,0,.12);

            .rdt_TableCell{
               display: table-cell;
               vertical-align: middle;
               padding: 1rem !important;
               border-right: 1px solid rgba(0,0,0,.12);

               &:last-child{
                  border-right: 0px;
               }
            }
         }
      }
   }
   &.react-dataTable-border-bottom{
      border-radius: 0px 0px 0.428rem 0.428rem;
   }
   &.react-dataTable-no-header{
      header {
         display: none;
      }
   }

   &.react-dataTable-wSelect{
      .rdt_TableHead {
         .rdt_TableHeadRow {
            .rdt_TableCol:first-child {
               visibility: hidden;
            }
         }
      }
      .rdt_TableBody {
         .rdt_TableRow {
            .rdt_TableCell:first-child {
               width: 35px;             
               padding-right: 0px !important;  
            }
         }
      }
   }
   &.react-dataTable-skipoverflow {
      overflow: visible !important;   
   }
}

.btn:first-child:active {
   border-color: transparent;
}
.btn.btn-flat-link:active {
   border-color: transparent !important;
}

.dark-layout{
   .react-dataTable{
      .rdt_TableHead {
         .rdt_TableHeadRow{
            border-color: #343d55 !important;
         }
      }
      .rdt_TableBody{
         .rdt_TableRow{
            border-bottom: 1px solid #343d55 !important;

            .rdt_TableCell{
               border-right: 1px solid #343d55 !important;

               &:last-child{
                  border-right: 0px !important;
               }
            }
            &:nth-child(2n+1){
               background-color: darken(#283046, 2%);
            }
            &:hover{
               background-color: darken(#283046, 4%);
            }
         }
      }
      .rdt_Pagination{
         border: 0px;
      }
   }
}

// **
.calendar-content {
   height: calc(var(--vh, 1vh) * 100 - (calc(2rem * 1) + 4.45rem + 3.35rem + 5.6rem)) !important;
   width: 100% !important;   

   > .content-wrapper {      
      width: inherit;

      > .calendar-wrapper {
         height: inherit;

         .table-card {
            height: inherit;

            > .card-body {
               height: inherit;

               .table-card-scroll {
                  height: calc(100% - 45px - 1rem);
                  overflow: auto;
                  position: relative;      
                  padding-right: 15px;            
               }
            }
         }         
      }
   }
}
.bg-calendar-info {
   background: rgba(#3498db, 60%) !important; 
   border-radius: 50% !important;

   &.badge {
      border-radius: 5px !important;
   }
}

.bg-calendar-inspection {
   background: rgba(#e67e22, 60%) !important; 
}
.bg-calendar-payment-delay {
   background: rgba(#1e8449, 60%) !important;
}
.bg-calendar-car-return {
   background: rgba(#e74c3c, 60%) !important;
}
.bg-calendar-debtor-visit {
   background: rgba(#9b59b6, 60%) !important;
}
/*
.calendar-table-wrapper {   
   > .content-wrapper {
      width: 100%;

      > .content-body {
         width: 100%;         

         > .card {
            height: inherit;
            width: 100%;

            > .card-body {
               height: calc(100% - 80px);
               width: 100%;               

               .table-responsive {
                  height: 100%;
                  overflow: scroll;
               }
            }
         }
      }
   }   
}*/
.item_events_container {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;

   .item_events_title {
      width: 100%;
      font-size: 0.8rem;
      padding: 5px 5px 5px 5px;      
      white-space: nowrap;
      line-height: 30px;
      height: 40px;
   }
   .item_events_holder {
      padding: 5px 5px 5px 0px;      
      height: 40px;

      .item_events_marker {
         display: flex;
         align-items: center;
         justify-content: center;
         width: 30px;
         height: 30px;

         &.circle {
            border-radius: 15px;
         }
         &.square {
            border-radius: 2px;
         }
         &.calendar_event {
            margin-left: 5px;
            cursor: pointer;
         }
      }      
   }
   .item_events_holder + .item_events_holder {
      .item_events_marker {
         margin-left: 0 !important;
      }
   }
}

//** Stepper
.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-subtitle {
    font-size: 1rem !important;
}
.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-title {
    margin-bottom: 0.3rem !important;
}

// ** utils
.white-space-nowrap {
   white-space: nowrap !important;
}

// ** car details car-details-indicator
.car-details {
   position: relative;
   padding-left: 6px;

   .car-details-indicator {
      position: absolute;
      width: 1px;
      height: 100%;
      background: #3b4253;
      left: 0px;

      &.active{
         background: #47be7d;
      }
   }
}

.file-gallery-item {
   display: flex;
   flex-direction: column;
   width:100%;
   height:100%;
   padding-bottom: 1rem;

   .file-gallery-image {
      display: block;
      position: relative;
      overflow: hidden;
      padding: .5rem;
      background: rgba(0,0,0,.05);
      height: 160px;

      img {
         position: absolute;
         width: 100%;
         object-fit: contain;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }
   }
   .file-gallery-placeholder {
      display: flex;
      flex: 1;
      overflow: hidden;
      padding: .5rem;
      text-align: center;
      background: rgba(0,0,0,.05);
      justify-content: center;
      align-items: center;

      img {
         width: 100%;
         object-fit: contain;
      }
   }
   .file-gallery-loading {
      display: flex;
      padding: .5rem;
      background: rgba(0,0,0,.05);
      justify-content: center;
      align-items: center;
      min-height: 160px;

      .fallback-spinner {
         width: 100%;
      }
   }
   .file-gallery-date{
      background: rgba(0,0,0,.05);
      padding: .3rem 0;
      font-size: .9rem;
      text-align: center;
   }
}

.avatar-holder {
   position: relative;
   display: block;
   width: 42px;
   height: 42px;

   .avatar{
      background: transparent;

      &.avatar-improved{
         height: 42px;
         display: block;
         position: relative;
         overflow: hidden;

         img{
            position: absolute;
            max-width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }
      }
   }
}

// ** Wizard
.vertical-wizard {
   .bs-stepper {
      .bs-stepper-header {
         padding: 0 1.5rem 1rem;
      }
   }
}

// ** Navigation minor changes
.vertical-layout.vertical-menu-modern .main-menu .navigation > li > a svg,
.vertical-layout.vertical-menu-modern .main-menu .navigation > li > a i {
   margin-top: -3px;
}

/*
@include media-breakpoint-down(768px) {
   .car-details {
      display: none;
   }
}*/

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover, .btn-group > .btn:focus,
.btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
   z-index: auto;
}

// Nav-pills nav-link
.nav-pills .nav-link {
   padding: 1rem;
   margin-right: 0.5rem;
}
@media (min-width: 768px) {
   .nav-pills .nav-link {
      margin-right: 0rem;
   }
}

@media (min-width: 768px) {
   .w-md-auto {
      width: auto !important;
   }
}

// Report balance
.report-balance {
   width: 450px;
   float: right;

   .report-balance-column {
      width: 150px;
   }
}


@media (max-width: 768px) {
   .report-balance {
      width: 100%;
      float: left;
   
      .report-balance-column {
         width: 33.3%;
      }
   }
}

// Flatpicker
.flatpickr-input-hidden {
   > input {
      display: none;
   }

   .flatpickr-calendar {
      width: 100%;
      //padding: 0.5rem;

      .flatpickr-current-month {
         padding-top: 5px;
      }

      .flatpickr-months .flatpickr-prev-month,
      .flatpickr-months .flatpickr-next-month {
         top: 0px;
      }
   }

}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
   color: inherit !important;
}

// Condensed html content
.html-condensed {
   padding-left: 1rem;

   p, ul, ol, h1, h2, h3, h4, h5{
      margin-bottom: 0.5rem;
   }
}

// Image cropping
.crop-section {
   position: relative;
   width: 100%;

   .crop-container {
      position: relative;
      width: 100%;
      height: 200px;
      background: transparent;

      @media only screen and (min-width: 768px) {
         height: 400px;
      }
   }

   .crop-controls{
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;

      > button {
         display: block
      }
   }
}

// Tree view
.tree-view {
   .tree-view-container {
      background: #f8f8f8;      
      display: flex; 
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0.5rem 0.5rem 1rem;      
   }
   > .tree-view-wrapper {
      margin-top: 0.5rem;
      border-radius: 5px;
      overflow: hidden;

      > .tree-view-container {                  
         > .tree-view-title {
            > .tree-view-angle {
               display: none;
            }
         }
      }
      &:first-child {
         margin-top: 0px;
      }
   }   

   .tree-view-wrapper {
      &:hover {
         > .tree-view-container {
            background: darken(#f8f8f8, 2%);
         }
      }
   }

   .tree-view-title {
      display: flex;
      align-items: center; 
      cursor: pointer;

      .tree-view-angle {
         margin-right: 0.5rem;
         font-size: .4em;
      }

      .tree-view-name {
         font-weight: 600;
         font-size: .9em;
         margin-right: 1rem;

         &.active {
            text-decoration: underline;
         }
      }
      .tree-view-subs {
         font-size: .9em;
         font-weight: 400;
         font-style: italic;
      }
   }

   &.select-element {      
      .tree-view-container {         
         padding: 0.85rem 0.5rem 0.85rem 1.25rem;      
      }
   }
}
.dark-layout {
   .tree-view {
      .tree-view-container {
         background: #161d31;    
      }

      .tree-view-wrapper {
         &:hover {
            > .tree-view-container {
               background: darken(#161d31, 2%);
            }
         }
      }
   }   
}

// Dropzone
.dropzone{
   min-height: 150px !important;
}

// Other
.cursor-default {
   cursor: default !important;
}
.w-30 {
   width: 30% !important;
}
.cut-filename { 
   display: block;
   text-overflow: ellipsis;
   overflow: hidden; 
   max-width: 250px; 
   height: 1.2em;
   white-space: nowrap;
 }

.no-selection {
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.main-menu ul.navigation-main {
   padding-bottom: 3rem;
}

.text-truncate-report {
   width: 150px;
   overflow: hidden; 
   white-space: nowrap; 
   text-overflow: ellipsis;   
}

.intl-dropdown{
   position: absolute;
   right: 0px;
   top: 0px;
}

.ReactModalPortal .ReactModal__Overlay {
   z-index: 1050 !important;
}

.pdf-container {
   visibility: hidden;
}

.table.pdf-table {
   border: 1px solid rgba(0,0,0,0.10) !important;
   
   tbody {
      > tr {
         > td {
            border-bottom: 1px solid rgba(0,0,0,0.10) !important;
            border-right: 1px solid rgba(0,0,0,0.10) !important;

            &:last-child {
               border-right: 0px !important;
            }

            .licence-plate {
               padding: 0px !important;
               background: none !important;
            }
         }
      }
   }
}

@media (min-width: 1200px) {
   .modal-xl {
      width: 1100px !important;
      margin-right: auto !important;
      margin-left: auto !important;
   }
}